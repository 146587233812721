import React, { useState, useEffect } from 'react';
import './Header.css';
import logo from '../Images/logo_white.png';
import logo1 from '../Images/logo_color.png';
import corporateWhite from '../Images/Corporate Video.png';
import corporateBlack from '../Images/Corporate Video1.png';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Add a scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        // When scrolled more than 10 pixels
        document.getElementById('header').style.padding = '1rem 0';
        document.getElementById('logo').style.display = 'none';
        document.getElementById('logo1').style.display = 'flex';
        document.getElementById('header').style.backgroundColor = '#f9f9f9';
        document.getElementById('atagOne').style.color = '#000';
        document.getElementById('atagTwo').style.color = '#000';
        document.getElementById('atagThree').style.color = '#000';
        document.getElementById('atagFour').style.color = '#000';
        document.getElementById('line1').style.backgroundColor = '#000';
        document.getElementById('line2').style.backgroundColor = '#000';
        document.getElementById('line3').style.backgroundColor = '#000';
        document.getElementById('menuFlex').style.backgroundColor = '#f9f9f9';
        document.getElementById('corporate').style.display = 'none';
        document.getElementById('corporate1').style.display = 'flex';
        setScrolling(true);
      } else {
        // When scrolled back to the top
        document.getElementById('header').style.padding = '4rem 0';
        document.getElementById('logo').style.display = 'flex';
        document.getElementById('logo1').style.display = 'none';
        document.getElementById('header').style.backgroundColor = 'unset';
        document.getElementById('atagOne').style.color = '#fff';
        document.getElementById('atagTwo').style.color = '#fff';
        document.getElementById('atagThree').style.color = '#fff';
        document.getElementById('atagFour').style.color = '#fff';
        document.getElementById('line1').style.backgroundColor = '#fff';
        document.getElementById('line2').style.backgroundColor = '#fff';
        document.getElementById('line3').style.backgroundColor = '#fff';
        document.getElementById('menuFlex').style.backgroundColor = 'unset';
        document.getElementById('corporate').style.display = 'flex';
        document.getElementById('corporate1').style.display = 'none';
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section>
      <div className={`header ${menuOpen ? 'menu-open' : ''}`} id="header">
        <div className='custom-container'>
          <div className='header-logo'>
            <a className='logo' id="logo" href="/"><img src={logo} alt="ASA - logo" /></a>
            <a className="logo1" id="logo1" href="/"><img src={logo1} alt="ASA - logo" /></a>
          </div>
          <div className={`header-menu ${menuOpen ? 'active' : ''}`} id="header-menu" onClick={closeMenu}>
            <div className='menu-flex' id='menuFlex'>
              <a href='#about-section' id='atagOne'>ABOUT US</a>
              <a href='#objectives-section' id='atagTwo'>OBJECTIVES</a>
              <a href='#capabilities-section' id='atagThree'>CAPABILITIES</a>
              <a href='#footer-section' id='atagFour'>CONTACT US</a>

              <div class="thumbnail" id="openModal"  onClick={openModal}>
                  <img src={corporateWhite} id='corporate' alt="Video Thumbnail"/>
                  <img src={corporateBlack} id='corporate1' alt="Video Thumbnail"/>
              </div>
            </div>
          </div>
          <div className={`hamburger ${menuOpen ? 'active' : ''}`} id="hamburger" onClick={toggleMenu}>
            <div className={`line line1`} id="line1"></div>
            <div className={`line line2`} id="line2"></div>
            <div className={`line line3`} id="line3"></div>
          </div>
        </div>
      </div>

      <div
        className="modal"
        id="myModal"
        style={{ display: isModalOpen ? 'block' : 'none' }}
      >
        <div className="modal-content">
          <span className="close" id="closeModal" onClick={closeModal}>
            &times;
          </span>
          <iframe
            id="videoFrame"
            src={`https://player.vimeo.com/video/871803041?badge=0&autoplay=${isModalOpen ? '1' : '0'}&quality_selector=1&progress_bar=1&player_id=0&app_id=58479`}
            frameBorder="0"
            allow="autoplay; fullscreen"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Header;
