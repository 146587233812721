import React, { useState } from 'react';
import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import Preloader from './Components/Preloader/Preloader'; // Import the Preloader component
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <BrowserRouter>
        {loading ? null : <Header />} {/* Render Header only when loading is false */}
        {loading ? (
          <Preloader setLoading={setLoading} /> // Display the preloader until loading is complete
        ) : (
          <Home/>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;