import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Navigation } from 'swiper/modules'; // Remove the duplicate import

// Other code...

import './Home.css';
import './styles.css';

import banner1 from '../Images/slide01.jpg';
import banner2 from '../Images/slide02.jpg';
import banner3 from '../videos/slide3.mp4';
import banner4 from '../videos/slide4.mp4';

import startquotes from '../Images/startquotes.png'

import aboutimage from '../Images/aboutus.jpg';
import aboutquote from '../Images/end_quotes.png';

import member1 from '../Images/manikkam.png'
import member7 from '../Images/Gianni-Lettieri.jpg'
import member8 from '../Images/Pietro-Pascale.jpg'
import member2 from '../Images/rajkumar.jpg'
import member3 from '../Images/ravikuri.jpg'
import member4 from '../Images/hariharasudan.png'
import member5 from '../Images/vaibav.jpg'
import member6 from '../Images/govindrajkumar.jpg'

import objectives1 from '../Images/objectives/maintenance_repair.svg'
import objectives2 from '../Images/objectives/logistic.svg'
import objectives3 from '../Images/objectives/enginerepair.svg'
import objectives4 from '../Images/objectives/aerospacetraining.svg'
import objectives5 from '../Images/objectives/aviationpainting.svg'
import objectives6 from '../Images/objectives/landinggear.svg'
import objectives7 from '../Images/objectives/processinnovation.svg'
import objectives8 from '../Images/objectives/aerospace services.svg'

import aircraft from '../Images/Aircraft-Models.jpg'
import Components from '../Images/Components.jpg'
import approvals from '../Images/Approvals.jpg'
import Structural from '../Images/Structural.jpg'
import Logistic from '../Images/Logistic.jpg'
import Painting from '../Images/Painting.jpg'
import additionalcapability from '../Images/Additional-capabilities.jpg'

import bullet1 from '../Images/bullert_small.png'
import bullet2 from '../Images/bullert_small.png'
import bullet3 from '../Images/bullert_small.png'
import bullet4 from '../Images/bullert_small.png'

import footerlogo from '../Images/logo_color.png'
import footerimage from '../Images/footerimage.jpg'
import phone from '../Images/phone.svg'
import email from '../Images/email.svg'
import sendicon from '../Images/sendicon.png'
import signatures1 from '../Images/signatures1.svg'


function Home() {
  const [showElements, setShowElements] = useState(false);

  const [responseMessage, setResponseMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);



  // Trigger the fade-in effect when the component mounts
  useEffect(() => {
    setShowElements(true);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    number: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate the entire form
    if (validateForm()) {
      setIsSubmitting(true);
      // Form is valid, send the form data to the server
      sendEmail(formData);
    } else {
      // Form has errors, do not submit
      // console.log('Form validation failed');
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: formData.name.trim() ? '' : 'Name is required',
      number: formData.number.trim() ? '' : 'Phone number is required',
      email: formData.email.trim() ? '' : 'Email is required',
      message: formData.message.trim() ? '' : 'Message is required',
    };

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (formData.email.trim() && !emailPattern.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    const numberPattern = /^[0-9]+$/;
    if (formData.number.trim() && !numberPattern.test(formData.number)) {
      newErrors.number = 'Invalid phone number format';
    }

    setErrors(newErrors);

    // Check if any errors exist in the form
    return Object.values(newErrors).every((error) => error === '');
  };

  const sendEmail = (formData) => {
    axios
      .post('https://akashsakthi.com/testmail.php', JSON.stringify(formData), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        // Handle success, maybe show a success message to the user
        setResponseMessage('Email sent successfully');
        setFormData({
          name: '',
          number: '',
          email: '',
          message: '',
        });
        setIsSubmitting(false);
        setTimeout(() => {
          setResponseMessage('');
        }, 5000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          setResponseMessage('Error occurred while sending the email.');
        } else {
          setResponseMessage('Unknown error occurred.');
        }
      });
  };
  
  

  return (
    <section className='homepage'>
      <div className={`homepage-swiper ${showElements ? 'fade-in show' : ''}`}>
      <Swiper
        centeredSlides={true}
        effect='fade' // Set the fade effect
        loop={true} // Set the loop to true for continuous looping
        speed={1000}
        autoplay={{
          delay: 4000, // Adjust the delay as needed
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[EffectFade, Autoplay, Navigation]}
        className='mySwiper'
      >
          <SwiperSlide className='Swiper-Slide'>
            <img src={banner1} alt='ASA - Banner images' />
            <div>
              <img className='start' src={startquotes} alt='ASA - quotes images' />
              <p>Aim to provide world-class MRO capabilities to Indian Aerospace & Aviation sector</p>
              <img className='end' src={startquotes} alt='ASA - quotes images' />
            </div>
          </SwiperSlide>
          <SwiperSlide className='Swiper-Slide'>
            <img src={banner2} alt='ASA - Banner images' />
            <div>
              <img className='start' src={startquotes} alt='ASA - quotes images' />
              <p>Aim to provide world-class MRO capabilities to Indian Aerospace & Aviation sector</p>
              <img className='end' src={startquotes} alt='ASA - quotes images' />
            </div>

          </SwiperSlide>
          <SwiperSlide className='Swiper-Slide'>
            {/* <img src={banner3} alt='' /> */}
            <video autoPlay muted loop  controls={false}>
              <source src={banner3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div>
              <img className='start' src={startquotes} alt='ASA - quotes images' />
              <p>Aim to provide world-class MRO capabilities to Indian Aerospace & Aviation sector</p>
              <img className='end' src={startquotes} alt='ASA - quotes images' />
            </div>
          </SwiperSlide>
          <SwiperSlide className='Swiper-Slide'>
            {/* <img src={banner4} alt='' /> */}
            <video autoPlay muted loop controls={false}>
              <source src={banner4} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div>
              <img className='start' src={startquotes} alt='ASA - quotes images' />
              <p>Aim to provide world-class MRO capabilities to Indian Aerospace & Aviation sector</p>
              <img className='end' src={startquotes} alt='ASA - quotes images' />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className='about-section' id='about-section'>
        <div className='about-section-flex'>
          <div className='col1'>
            <div className='about-content'>
              <div className='heading-flex'>
                <img src={aboutquote} alt='ASA - icon Images' />
                <h1>Akash Sakthi Aviation</h1>
              </div>
              <p>Indian conglomerate <a className='otherlink' href='https://sakthigroup.com/index.php' target='blank'>Sakthi Group</a> founded in 1921 and Europe's largest independent MRO
                  company <a className='otherlink' href='https://www.atitech.it' target='blank'>Atitech</a> with 30 years of experience, co-founded Akash Sakthi Aviation to provide
                  world-class MRO capabilities and operations to the Indian aviation market. The company
                  aims to become a reliable MRO partner in the competitive and growing Indian aviation
                  market.</p>
            </div>
          </div>
          <div className='col2'>
            <img src={aboutimage} alt='ASA - About Images' />
          </div>
        </div>
      </div>

      <div className='members-section' id="members-section">
        <div className='custom-container'>
          <div className='heading-flex'>
            <img src={aboutquote} alt='ASA - icon Images' />
            <h2>Key Members</h2>
          </div>
          <div className='members-details-flex'>
            <div className='member-details'>
              <div>
                <img src={member1} alt='members images' />
              </div>
              <p>Dr. M Manickam</p>
            </div>
            <div className='member-details'>
              <div>
                <img src={member7} alt='members images' />
              </div>
              <p>Gianni Lettieri</p>
            </div>
            <div className='member-details'>
              <div>
                <img src={member2} alt='members images' />
              </div>
              <p>T Rajkumar</p>
            </div>
            <div className='member-details'>
              <div>
                <img src={member3} alt='members images' />
              </div>
              <p>Ravi Kuri</p>
            </div>
            <div className='member-details'>
              <div>
                <img src={member8} alt='members images' />
              </div>
              <p>Pietro Pascale</p>
            </div>
            <div className='member-details'>
              <div>
                <img src={member4} alt='members images' />
              </div>
              <p>Hari Hara Sudhan</p>
            </div>
            <div className='member-details'>
              <div>
                <img src={member5} alt='members images' />
              </div>
              <p>Vaibhav D</p>
            </div>
            <div className='member-details'>
              <div>
                <img src={member6} alt='members images' />
              </div>
              <p>Govindh Rajkumar</p>
            </div>
          </div>
          
        </div>
      </div>

      <div className='objectives-section' id='objectives-section'>
        <div className='custom-container'>
          <div className='heading-flex'>
            <img src={aboutquote} alt='' />
            <h2>Objectives</h2>
          </div>
          <div className='objectives-flex'>
            <div className='objective-inner'>
              <div><img src={objectives1} alt='objectives icons' /></div>
              <p className='para1'>Engage in Maintenance, Repair, and Overhaul (MRO) services in the Indian Aviation & Aerospace Industry for all types of aircraft, helicopters, UAV, Ground Based Radars & Avionics.</p>
            </div>
            <div className='objective-inner'>
              <div><img src={objectives2} alt='objectives icons' /></div>
              <p className='para2'>Facilitate logistics services related to the aerospace sector, including but not limited to spare parts repair, holding, distribution, and management of all aircraft equipment and systems, Ground Handling Equipment etc.</p>
            </div>
            <div className='objective-inner'>
              <div><img src={objectives3} alt='objectives icons' /></div>
              <p className='para3'>Specialise in engine repair and spare parts repair for all aircraft, Helicopter and UAV engines related components.</p>
            </div>
            <div className='objective-inner'>
              <div className='fouth'><img src={objectives4} alt='objectives icons'/></div>
              <p>Provide Training for Aerospace Engineers & Technicians.</p>
            </div>
            <div className='objective-inner'>
              <div><img src={objectives5} alt='objectives icons' /></div>
              <p className='para5'>Undertake supply of aviation grade paint, and for sanding, stripping and painting of Aircraft, in compliance with environmental regulations.</p>
            </div>
            <div className='objective-inner'>
              <div><img src={objectives6} alt='objectives icons' /></div>
              <p>Undertake Landing Gear Overhauls and Rotable Overhaul for all western fleet aircraft.</p>
            </div>
            <div className='objective-inner'>
              <div><img src={objectives7} alt='objectives icons' /></div>
              <p>Undertake developments of new technologies for process innovation and development of new services.</p>
            </div>
            <div className='objective-inner'>
              <div><img src={objectives8} alt='objectives icons' /></div>
              <p className='para8'>Undertake activities, projects, and services closely related to the aerospace, aviation, and defence sectors, which are in line with the Company's expertise and capabilities.</p>
            </div>
          </div>
          <div id="capabilities-section"></div>
        </div>
      </div>


      <div className='capabilities-section'>
        <div className='custom-container'>
          <div className='heading-flex'>
            <img src={aboutquote} alt='' />
            <h2>Our Capabilities</h2>
          </div>
          <div className='capabilities-flex'>
            <div className='capabilities1'>
              <a href="https://www.atitech.it/warehouses/" className='capabilities-inner' target='_blank'>
                <div><img src={Logistic} alt='Capabilities images' /></div>
                <p>Integrated Logistic Support</p>
              </a>
              <a href='https://www.atitech.it/painting/' className='capabilities-inner' target='_blank'>
                <div><img src={Painting} alt='Capabilities images' /></div>
                <p>Aircraft Painting</p>
              </a>
            </div>
            <div className='capabilities1'>
              <div className='capabilities-inner'>
                <div><img src={Components} alt='Capabilities images' /></div>
                <p>Components Support</p>
              </div>
              <a href='https://www.atitech.it/approvals/' className='capabilities-inner' target='_blank'>
                <div><img src={approvals} alt='Capabilities images' /></div>
                <p>Approvals</p>
              </a>
            </div>
            <div className='capabilities1'>
              <div></div>
              <div className='capabilities-inner'>
                <div><img src={Structural} alt='Capabilities images' /></div>
                <p>Structural Repairs</p>
              </div>
              <a href='https://www.atitech.it/capabilities/' className='capabilities-inner' target='_blank'>
                <div><img src={aircraft} alt='Capabilities images' /></div>
                <p>Aircraft Models</p>
              </a>
            </div>
          </div>
          <div className='add-capabilities'>
              <div className='capabilities-inner'>
                <div className='add-capabilitie-img'>
                   <img src={additionalcapability} className='additional' alt='Capabilities images' />
                    <ul className='add-content'>
                      <li>
                        <img src={bullet1} alt='' />
                        <p>DCMA Audits.</p>
                      </li>
                      <li>
                        <img src={bullet2} alt='' />
                        <p>POL, HAZMAT & HAZWASTE Programs.</p>
                      </li>
                      <li>
                        <img src={bullet3} alt='' />
                        <p>AS9110 & AS9210 compliance.</p>
                      </li>
                      <li>
                        <img src={bullet4} alt='' />
                        <p>Logistics Scheduling & Maintenance Support for CH-47, AH-64 & UH-60 models.</p>
                      </li>
                    </ul>
                </div>
                <p>Additional Capabilities</p>
              </div>
          </div>
        </div>
      </div>


      <div className='footer-section' id='footer-section'>
        <div className='custom-container'>
          <div className='footer-flex'>
            <div className='footer-col1'>
              <div className='footer-col1-flex'>
              <img src={footerlogo} alt='ASA - Footer logo' />
              <div>
                <p> #180, Race Course Road, </p>
                <p>Race Course, Coimbatore, </p>
                <p>Tamil Nadu 641018, India.</p>
              </div>
              <div className='social-icon'>
                <img src={email} alt='email-icon image' />
                <a href='mailto:info@akashsakthi.com'>info@akashsakthi.com</a>
              </div>
              <div className='social-icon'>
              <img src={phone} alt='call-icon image' />
              <a href='tel:+91 422 4322 222'>+91 422 2368 289</a>
              </div>
              
              <div className='quick-links'>
                <h3>Quick Links</h3>
                <div>
                  <a href='#'>Home</a>
                  <a href='#about-section' id='atagOne'>About Us</a>
                  <a href='#capabilities-section'>Capabilities</a>
                  <a href='#footer-section'>Contact Us</a>
                </div>
              </div>
              </div>
            </div>

            <div className='footer-col2'>
              <img src={footerimage} alt='footer asa image' />
            </div>

            <div className='footer-col3'>
              <form
                onSubmit={handleSubmit}
              >
                <h3>Get in Touch</h3>
                <div className='form-group'>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Name'
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <div className='error'>{errors.name}</div>}
                </div>
                <div className='form-group'>
                  <input
                    type='tel'
                    id='number'
                    name='number'
                    placeholder='Phone number'
                    value={formData.number}
                    onChange={handleChange}
                  />
                  {errors.number && <div className='error'>{errors.number}</div>}
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    id='email'
                    name='email'
                    placeholder='Email'
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <div className='error'>{errors.email}</div>}
                </div>
                <div className='form-group'>
                  <textarea
                    id='message'
                    name='message'
                    placeholder='Message'
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && <div className='error'>{errors.message}</div>}
                </div>

                <div className='form-sendbtn'>
                  <input type='submit' className='submit-btn' value='SEND' />
                  <div className='formbtn-img'>
                    <img className='send-img1' src={sendicon} alt='' />
                    <img className='send-img2' src={sendicon} alt='' />
                  </div>
                  <div className='form-preloader' style={{ display: isSubmitting ? 'flex' : 'none' }}><div></div></div>
                </div>
                <p id='responseMessage'>{responseMessage}</p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="copyrights-section">
                <div class="custom-container">
                    <div class="copy-rights">
                        <p>Copyright 2024 - Akash Sakthi Aviation, AlI Rights Reserved.</p>
                    </div>
                    <a href="https://www.signatures1.com/" target="_blank" class="signatures-logo" rel="noreferrer">
                        <p>Signatures1</p>
                        <div class="logo-reveal">
                            <img src={signatures1} alt="signaturelogo" />
                        </div>
                    </a>
                </div>
            </div>

    </section>
  );
}

export default Home;

