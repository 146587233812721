import React, { useState, useEffect, useRef } from 'react';
import './Preloader.css';
import Video from '../videos/Intro.mp4';
import preimage from '../Images/logo_white.png';

const Preloader = ({ setLoading }) => {
  const [fade, setFade] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    const video = videoRef.current;

    // Function to handle video metadata load event
    const handleVideoMetadata = () => {
      // Once video metadata is loaded, we know the video has fully loaded
      video?.removeEventListener('loadedmetadata', handleVideoMetadata);

      // Play the video when the component mounts
      video.play().catch(() => {
        // Add a click event to play the video if autoplay fails
        document.body.addEventListener('click', handleUserInteraction, { once: true });
      });

      // Simulate a delay for the preloader (e.g., 4 seconds)
      const preloaderTimeout = setTimeout(() => {
        setFade(true);
        setTimeout(() => {
          setLoading(false);
        }, 200); // Adjust the duration of the transition
      }, 5000); // Adjust this timeout to match the video's duration
    };

    const handleUserInteraction = () => {
      video.play();
    };

    // Add an event listener to detect when video metadata is loaded
    video?.addEventListener('loadedmetadata', handleVideoMetadata);

    // Cleanup function to remove the event listener
    return () => {
      video?.removeEventListener('loadedmetadata', handleVideoMetadata);
      document.body.removeEventListener('click', handleUserInteraction);
    };
  }, [setLoading]);

  return (
    <div className={`preloader-container ${fade ? 'fade-out' : ''}`}>
      <video ref={videoRef} autoPlay muted playsInline loop preload="auto" loading="lazy">
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <img src={preimage} alt="" />
    </div>
  );
};

export default Preloader;
